import { Typography, Input, Button, Form } from 'antd';
import { useContext, useState } from 'react';
import QrContext from '../contextQrCode';
import configData from '../QRcodeAppConfig.json';

const { Text } = Typography;
const { TextArea } = Input;
const { Item } = Form;

const OptionText = (isEnter) => {
  
  const { setTextQR, isValidQR, setIsValidQR } = useContext(QrContext);
  const [valStatus, setValStatus] = useState('sucess');

  const validator = (objUrl = '') => {
    let regex = new RegExp(/(.{3,})/g);
    let result = '';
    if(objUrl.match(regex)) {
      let teste = regex.exec(objUrl);
      result = teste[0];
      // console.log( result);
      setValStatus('sucess');
      setTextQR(result);
      setIsValidQR(true);
    } else {
      setIsValidQR(false);
      setValStatus('error');
      // setTextQR('invalid');
    }
  };

  const handleChange = (e) => {
    e.preventDefault();

    validator(e.target.value);

  }

  return (
  <>
    <Typography className={`typography-container ${isEnter ? 'opt-enter' : 'opt-out'}`}>
      <Text className="txt-desc">Enter your text here.</Text>
      <Item
        hasFeedback
        validateStatus={valStatus} help={isValidQR ? '' : 'Type your text!'}
      >
        <TextArea rows={6} className="txtAreaOpt" placeholder="Type or paste your text here and immediately your qr code will be created."
          onChange={handleChange}
        />
      </Item>
      <Button href={configData.DEFAULT_LINK_3} target="_blank" type="primary" className="margins" shape="round"> {configData.BTN_AZUL} </Button>
    </Typography>
  </>
);

}

export default OptionText;