import { Typography, Input, Button, Form } from 'antd';
import { useContext, useState } from 'react';
import QrContext from '../contextQrCode';
import configData from '../QRcodeAppConfig.json';

// (?:https:\/\/)(?:www\.)?(instagram\.com\/)(?:(?:\w)*#!\/)?(?:pages\/)?(?:[\w\-]*\/)*?(\/)?([^/?]*)(\/)?(\?)?(\w*)?(\.)?(\=)?(\w*)?(\/)?(\w*)?

const { Text } = Typography;
const { Item } = Form;

const OptionInstagram = (isEnter) => {
  
  const { setInstagramQR, isValidQR, setIsValidQR } = useContext(QrContext);
  const [valStatus, setValStatus] = useState('sucess');

  
  const validator = (objUrl = '') => {
    setValStatus('sucess');
    let result = 'https://instagram.com/' + objUrl;
    setInstagramQR(result);
    setIsValidQR(true);
  };

  const handleChange = (e) => {
    e.preventDefault();
    validator(e.target.value);
  }


  return (
  <>
    <Typography className={`typography-container ${isEnter ? 'opt-enter' : 'opt-out'}`}>
      <Text className="txt-default txt-desc">Enter with your Instagram.</Text>
      <Item
        hasFeedback
        validateStatus={valStatus} help={isValidQR ? '' : 'Invalid URL! Do not need to input https://instagram.com/'}
      >
      <Input addonBefore="https://instagram.com/" onChange={handleChange} className="margins" />
      </Item>
      <Button href={configData.DEFAULT_LINK_3} target="_blank" type="primary" className="margins" shape="round"> 
      {configData.BTN_AZUL}
      </Button>
    </Typography>
  </>
);

}

export default OptionInstagram;