import { Typography, Input, Button, Form  } from 'antd';
import { useContext, useState } from 'react';
import QrContext from '../contextQrCode';
import configData from '../QRcodeAppConfig.json';

// ((?:(?:http?|ftp)[s]*:\/\/)[a-z0-9-%\/\&=?\.]+\.[a-z]{2,4}\/?([^\s<>\#%"\,\{\}\\|\\\^\[\]`]+)?)

const { Text } = Typography;
const { Item } = Form;

const OptionMp3 = (isEnter) => {
  
  const { setMp3QR, isValidQR, setIsValidQR } = useContext(QrContext);
  const [valStatus, setValStatus] = useState('sucess');
  const validator = (objUrl = '') => {
    let regex = new RegExp(/((?:(?:http?|ftp)[s]*:\/\/)[a-z0-9-%/&=?.]+\.[a-z]{2,4}\/?([^\s<>#%",{}\\|\\^[\]`]+)?)/g);
    let result = '';
    if(objUrl.match(regex)) {
      let teste = regex.exec(objUrl);
      result = teste[0];
      console.log( result);
      setValStatus('sucess');
      setMp3QR(result);
      setIsValidQR(true);
    } else {
      setIsValidQR(false);
      setValStatus('error');
      // setMp3QR('invalid');
    }
  };
  const handleChange = (e) => {
    e.preventDefault();

    validator(e.target.value);

  }

  return (
  <>
    <Typography className={`typography-container ${isEnter ? 'opt-enter' : 'opt-out'}`}>
      <Text className="txt-desc"> Enter your MP3 URL. </Text>
      <Item
        hasFeedback
        validateStatus={valStatus} help={isValidQR ? '' : 'Invalid URL! Need to include prefixed URL http://|https://'}
      >
        <Input onChange={handleChange} className="txtAreaOpt" placeholder="Copy and Paste the MP3 link here" />
      </Item>
      <Button href={configData.DEFAULT_LINK_3} target="_blank" type="primary" className="margins" shape="round"> {configData.BTN_AZUL} </Button>
    </Typography>
  </>
);

}

export default OptionMp3;