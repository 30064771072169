/* eslint-disable no-unused-vars */
// Importações do react e libs necessárias ________________________
import React, { useCallback, useRef, useState } from 'react';
import ImgCrop from 'antd-img-crop';
import { Button, Typography, Tabs, Modal, Upload, message } from 'antd';
import { ColorPicker, useColor } from "react-color-palette";
// ‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾

// Importações dos icones dos botões ______________________________
import { 
  YoutubeFilled,
  LinkOutlined,
  QuestionCircleOutlined,
  FilePdfFilled,
  FileTextFilled,
  IdcardFilled,
  VideoCameraFilled,
  ContainerFilled,
  WhatsAppOutlined,
  AppstoreFilled,
  WifiOutlined,
  MessageFilled,
  AudioFilled,
  FacebookFilled,
  InstagramFilled,
  LinkedinFilled,
  TwitterCircleFilled,
  FileFilled,
  SoundFilled,
  CodeSandboxOutlined,
} from '@ant-design/icons';
// ‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾

// Importações css ________________________________________________
import "react-color-palette/lib/css/styles.css";
import './App.css';
// ‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾

// Importando componentes criados _________________________________
import OptionUrl from './optionsComponents/optionUrl';
import OptionPdf from './optionsComponents/optionPdf';
import OptionText from './optionsComponents/optionText';
import OptionVisitCard from './optionsComponents/optionVisitCard';
import OptionYoutube from './optionsComponents/optionYoutube';
import OptionVideo from './optionsComponents/optionVideo';
import OptionEmail from './optionsComponents/optionEmail';
import OptionWhats from './optionsComponents/optionWhats';
import OptionAppStores from './optionsComponents/optionAppStores';
import OptionWifi from './optionsComponents/optionWifi';
import OptionSms from './optionsComponents/optionSms';
import OptionPodcast from './optionsComponents/optionPodcast';
import OptionFacebook from './optionsComponents/optionFacebook';
import OptionInstagram from './optionsComponents/optionInstagram';
import OptionLinkedin from './optionsComponents/optionLinkedin';
import OptionTwitter from './optionsComponents/optionTwitter';
import OptionFile from './optionsComponents/optionFile';
import OptionMp3 from './optionsComponents/optionMp3';
import OptionSmartPack from './optionsComponents/optionSmartPack';
// ‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾

// Importando a parametrização ____________________________________
import configData from './QRcodeAppConfig.json';
// ‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾

// Importando o contexto __________________________________________
import QrContext from './contextQrCode';
import ContaLogin from './optionsComponents/contaLogin';
import QRComponent from './optionsComponents/qrComponnt';
import ShortUrlComponent from './ShortUrlComponent';
// ‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾

// Extraindo as variáveis conforme pede o ant design ______________
const { Title, Text } = Typography;
const { TabPane } = Tabs;
// ‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾

// Setando uma correção para exibir ou não o painel das opções ____
const inactiveForPanel = !configData.INACTIVE;
// ‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾

function App() {

  const [isValidQR, setIsValidQR] = useState(Boolean);
  const [imgd, setImgd] = useState('');
  const refTagImg = useRef(null);

// Variáveis de teste do contexto _________________________________
const[urlQR, setUrlQR] = useState('');
const[textQR, setTextQR] = useState('');
const[emailQR, setEmailQR] = useState('');
const[youtubeQR, setYoutubeQR] = useState('');
const[videoQR, setVideoQR] = useState('');
const[whatsQR, setWhatsQR] = useState('');
const[podcastQR, setPodcastQR] = useState('');
const[facebookQR, setFacebookQR] = useState('');
const[instagramQR, setInstagramQR] = useState('');
const[linkedinQR, setLinkedinQR] = useState('');
const[twitterQR, setTwitterQR] = useState('');
const[fileQR, setFileQR] = useState('');
const[mp3QR, setMp3QR] = useState('');
const[wifiQR, setWifiQR] = useState('');
const[smsQR, setSmsQR] = useState('');
// ‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾

// Estados para ativar a chamada dos botões _______________________
  const [ isClickedUrl, setIsClickedUrl]                = useState(inactiveForPanel);
  const [ isClickedPdf, setIsClickedPdf]                = useState(Boolean);
  const [ isClickedText, setIsClickedText]              = useState(Boolean);
  const [ isClickedVisitCard, setIsClickedVisitCard]    = useState(Boolean);
  const [ isClickedYoutube, setIsClickedYoutube]        = useState(Boolean);
  const [ isClickedVideo, setIsClickedVideo]            = useState(Boolean);
  const [ isClickedEmail, setIsClickedEmail]            = useState(Boolean);
  const [ isClickedWhats, setIsClickedWhats]            = useState(Boolean);
  const [ isClickedAppStores, setIsClickedAppStores]    = useState(Boolean);
  const [ isClickedWifi, setIsClickedWifi]              = useState(Boolean);
  const [ isClickedSms, setIsClickedSms]                = useState(Boolean);
  const [ isClickedPodCast, setIsClickedPodCast]        = useState(Boolean);
  const [ isClickedFacebook, setIsClickedFacebook]      = useState(Boolean);
  const [ isClickedInstagram, setIsClickedInstagram]    = useState(Boolean);
  const [ isClickedLinkedin, setIsClickedLinkedin]      = useState(Boolean);
  const [ isClickedTwitter, setIsClickedTwitter]        = useState(Boolean);
  const [ isClickedFile, setIsClickedFile]              = useState(Boolean);
  const [ isClickedMp3, setIsClickedMp3]                = useState(Boolean);
  const [ isClickedSmartPack, setIsClickedSmartPack]    = useState(Boolean);
// ‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾

// Função para ser chamada nos handle a decidir qual será aberta __
const clickedIn = (clicked) => {
  clicked === 'url' ? setIsClickedUrl(true) : setIsClickedUrl(false);
  clicked === 'pdf' ? setIsClickedPdf(true) : setIsClickedPdf(false) ;
  clicked === 'text' ? setIsClickedText(true) : setIsClickedText(false);
  clicked === 'visitCard' ? setIsClickedVisitCard(true) : setIsClickedVisitCard(false);
  clicked === 'youtube' ? setIsClickedYoutube(true) : setIsClickedYoutube(false);
  clicked === 'video' ? setIsClickedVideo(true) : setIsClickedVideo(false);
  clicked === 'email' ? setIsClickedEmail(true) : setIsClickedEmail(false);
  clicked === 'whats' ? setIsClickedWhats(true) : setIsClickedWhats(false);
  clicked === 'appStores' ? setIsClickedAppStores(true) : setIsClickedAppStores(false);
  clicked === 'wifi' ? setIsClickedWifi(true) : setIsClickedWifi(false);
  clicked === 'sms' ? setIsClickedSms(true) : setIsClickedSms(false);
  clicked === 'podcast' ? setIsClickedPodCast(true) : setIsClickedPodCast(false);
  clicked === 'facebook' ? setIsClickedFacebook(true) : setIsClickedFacebook(false);
  clicked === 'instagram' ? setIsClickedInstagram(true) : setIsClickedInstagram(false);
  clicked === 'linkedin' ? setIsClickedLinkedin(true) : setIsClickedLinkedin(false);
  clicked === 'twitter' ? setIsClickedTwitter(true) : setIsClickedTwitter(false);
  clicked === 'file' ? setIsClickedFile(true) : setIsClickedFile(false);
  clicked === 'mp3' ? setIsClickedMp3(true) : setIsClickedMp3(false);
  clicked === 'smartpack' ? setIsClickedSmartPack(true) : setIsClickedSmartPack(false);
}
// ‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾
const setValid = () => setIsValidQR(false);

// Funções que dispara a chamada verificadora _____________________
  const handleClickedUrl        = () => {clickedIn('url'); setValid();}
  const handleClickedPdf        = () => {clickedIn('pdf'); setValid();}
  const handleClickedText       = () => {clickedIn('text'); setValid();}
  const handleClickedVisitCard  = () => {clickedIn('visitCard'); setValid();}
  const handleClickedYoutube    = () => {clickedIn('youtube'); setValid();}
  const handleClickedVideo      = () => {clickedIn('video'); setValid();}
  const handleClickedEmail      = () => {clickedIn('email'); setValid();}
  const handleClickedWhats      = () => {clickedIn('whats'); setValid();}
  const handleClickedAppStores  = () => {clickedIn('appStores'); setValid();}
  const handleClickedWifi       = () => {clickedIn('wifi'); setValid();}
  const handleClickedSms        = () => {clickedIn('sms'); setValid();}
  const handleClickedPodcast    = () => {clickedIn('podcast'); setValid();}
  const handleClickedFacebook   = () => {clickedIn('facebook'); setValid();}
  const handleClickedInstagram  = () => {clickedIn('instagram'); setValid();}
  const handleClickedLinkedin   = () => {clickedIn('linkedin'); setValid();}
  const handleClickedTwitter    = () => {clickedIn('twitter'); setValid();}
  const handleClickedFile       = () => {clickedIn('file'); setValid();}
  const handleClickedMp3        = () => {clickedIn('mp3'); setValid();}
  const handleClickedSmartPack  = () => {clickedIn('smartpack'); setValid();}
// ‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾

// Usando o hook do ant design ____________________________________
  const [color, setColor] = useColor("hex", "#ffffff");
// ‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾

// Configurando o modal ___________________________________________
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
// ‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾

const onPreview = async (file) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  if (file.size > 1000000) {
    message.error('The file must be larger than 1MB')
  } else {
    reader.onloadend = () => {
      let b64 = reader.result;
      setImgd(b64);
    }
  }
}

  const handleImgd = () => setImgd('')

return (
    <>
      <Modal destroyOnClose cancelButtonProps={{ style: { display: 'none' } }} okButtonProps={{ style: { display: 'none' } }} width={760} title="How to" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
        <iframe title="Learn how to use" id="ytplayer" type="text/html" width="720" height="405" src="https://www.youtube.com/embed/ytN43Voti5I" frameborder="0" allowfullscreen /> 
      </Modal>
      <QrContext.Provider value={{ 
        urlQR, setUrlQR, isClickedUrl, setIsClickedUrl,
        textQR, setTextQR, isClickedText, setIsClickedText,
        emailQR, setEmailQR, isClickedEmail,
        youtubeQR, setYoutubeQR, isClickedYoutube,
        videoQR, setVideoQR, isClickedVideo,
        whatsQR, setWhatsQR, isClickedWhats,
        podcastQR, setPodcastQR, isClickedPodCast,
        facebookQR, setFacebookQR, isClickedFacebook,
        instagramQR, setInstagramQR, isClickedInstagram,
        linkedinQR, setLinkedinQR, isClickedLinkedin,
        twitterQR, setTwitterQR, isClickedTwitter,
        fileQR, setFileQR, isClickedFile,
        mp3QR, setMp3QR, isClickedMp3,
        wifiQR, setWifiQR, isClickedWifi,
        smsQR, setSmsQR, isClickedSms,
        isValidQR, setIsValidQR, imgd,
      }}>
        <div className="title-content">
          <Title level={1} className="title-txt">CREATE YOUR QR CODE HERE, NOW AND FOR FREE!</Title>
          <div className="container-howToUse">            
            <Button href={configData.LINK_HOW_TO_USE} target="_blank" className=" btn-how-to-use" shape="round" type="default" icon={<QuestionCircleOutlined />}> Help </Button>
          </div>
        </div>
        <div className="container-qrcodeGen">
          <div className="btns-wrapper">
              <Button disabled={configData.URL_ACTIVE       ? configData.INACTIVE : true} onClick={handleClickedUrl}       block className={`btn-edit ${ isClickedUrl ? 'border-btns-mark' : '' }`} icon={<LinkOutlined />}>URL</Button>
              <Button disabled={configData.PDF_ACTIVE       ? configData.INACTIVE : true} onClick={handleClickedPdf}       block className={`btn-edit ${ isClickedPdf ? 'border-btns-mark' : '' }`} icon={<FilePdfFilled />}>PDF</Button>
              <Button disabled={configData.TEXT_ACTIVE      ? configData.INACTIVE : true} onClick={handleClickedText}      block className={`btn-edit ${ isClickedText ? 'border-btns-mark' : '' }`} icon={<FileTextFilled />}>Text</Button>
              <Button disabled={configData.VISITCARD_ACTIVE ? configData.INACTIVE : true} onClick={handleClickedVisitCard} block className={`btn-edit sizeMinusTextSpanBtn ${ isClickedVisitCard ? 'border-btns-mark' : '' }`} icon={<IdcardFilled />}>Business Card</Button>
              <Button disabled={configData.YOUTUBE_ACTIVE   ? configData.INACTIVE : true} onClick={handleClickedYoutube}   block className={`btn-edit ${ isClickedYoutube ? 'border-btns-mark' : '' }`} icon={<YoutubeFilled />}>Youtube</Button>
              <Button disabled={configData.VIDEO_ACTIVE     ? configData.INACTIVE : true} onClick={handleClickedVideo}     block className={`btn-edit ${ isClickedVideo ? 'border-btns-mark' : '' }`} icon={<VideoCameraFilled />}>Video</Button>
              <Button disabled={configData.MP3_ACTIVE       ? configData.INACTIVE : true} onClick={handleClickedMp3}       block className={`btn-edit ${ isClickedMp3 ? 'border-btns-mark' : '' }`} icon={<SoundFilled />}>MP3</Button>
              <Button disabled={configData.EMAIL_ACTIVE     ? configData.INACTIVE : true} onClick={handleClickedEmail}     block className={`btn-edit ${ isClickedEmail ? 'border-btns-mark' : '' }`} icon={<ContainerFilled />}>E-mail</Button>
              <Button disabled={configData.WHATS_ACTIVE     ? configData.INACTIVE : true} onClick={handleClickedWhats}     block className={`btn-edit ${ isClickedWhats ? 'border-btns-mark' : '' }`} icon={<WhatsAppOutlined />}>Whatsapp</Button>
              <Button disabled={configData.WIFI_ACTIVE      ? configData.INACTIVE : true} onClick={handleClickedWifi}      block className={`btn-edit ${ isClickedWifi ? 'border-btns-mark' : '' }`} icon={<WifiOutlined />}>Wifi</Button>
              <Button disabled={configData.SMS_ACTIVE       ? configData.INACTIVE : true} onClick={handleClickedSms}       block className={`btn-edit ${ isClickedSms ? 'border-btns-mark' : '' }`} icon={<MessageFilled />}>SMS</Button>
              <Button disabled={configData.PODCAST_ACTIVE   ? configData.INACTIVE : true} onClick={handleClickedPodcast}   block className={`btn-edit ${ isClickedPodCast ? 'border-btns-mark' : '' }`} icon={<AudioFilled />}>Podcast</Button>
              <Button disabled={configData.FACEBOOK_ACTIVE  ? configData.INACTIVE : true} onClick={handleClickedFacebook}  block className={`btn-edit ${ isClickedFacebook ? 'border-btns-mark' : '' }`} icon={<FacebookFilled />}>Facebook</Button>
              <Button disabled={configData.INSTAGRAM_ACTIVE ? configData.INACTIVE : true} onClick={handleClickedInstagram} block className={`btn-edit ${ isClickedInstagram ? 'border-btns-mark' : '' }`} icon={<InstagramFilled />}>Instagram</Button>
              <Button disabled={configData.LINKEDIN_ACTIVE  ? configData.INACTIVE : true} onClick={handleClickedLinkedin}  block className={`btn-edit ${ isClickedLinkedin ? 'border-btns-mark' : '' }`} icon={<LinkedinFilled />}>Linkedin</Button>
              <Button disabled={configData.TWITTER_ACTIVE   ? configData.INACTIVE : true} onClick={handleClickedTwitter}   block className={`btn-edit ${ isClickedTwitter ? 'border-btns-mark' : '' }`} icon={<TwitterCircleFilled />}>Twitter</Button>
              <Button disabled={configData.FILE_ACTIVE      ? configData.INACTIVE : true} onClick={handleClickedFile}      block className={`btn-edit ${ isClickedFile ? 'border-btns-mark' : '' }`} icon={<FileFilled />}>File</Button>
              <Button disabled={configData.APPSTORES_ACTIVE ? configData.INACTIVE : true} onClick={handleClickedAppStores} block className={`btn-edit sizeMinusTextSpanBtn ${ isClickedAppStores ? 'border-btns-mark' : '' }`} icon={<AppstoreFilled />}>App Stores</Button>
              <Button disabled={configData.SMARTPACK_ACTIVE ? configData.INACTIVE : true} onClick={handleClickedSmartPack} block className={`btn-edit sizeMinusTextSpanBtn ${ isClickedSmartPack ? 'border-btns-mark' : '' }`} icon={<CodeSandboxOutlined />}>Smart packaging</Button>
          </div>
          <div className="options-wrapper">

            <div className="wrpper-opt-1">
              { isClickedUrl       ? configData.SIGNED ? !configData.INACTIVE ? configData.URL_ACTIVE       ? <OptionUrl />       : '' : '' : <ContaLogin /> : '' }
              { isClickedPdf       ? configData.SIGNED ? !configData.INACTIVE ? configData.PDF_ACTIVE       ? <OptionPdf />       : '' : '' : <ContaLogin /> : '' }
              { isClickedText      ? configData.SIGNED ? !configData.INACTIVE ? configData.TEXT_ACTIVE      ? <OptionText />      : '' : '' : <ContaLogin /> : '' }
              { isClickedVisitCard ? configData.SIGNED ? !configData.INACTIVE ? configData.VISITCARD_ACTIVE ? <OptionVisitCard /> : '' : '' : <ContaLogin /> : '' }
              { isClickedYoutube   ? configData.SIGNED ? !configData.INACTIVE ? configData.YOUTUBE_ACTIVE   ? <OptionYoutube />   : '' : '' : <ContaLogin /> : '' }
              { isClickedVideo     ? configData.SIGNED ? !configData.INACTIVE ? configData.VIDEO_ACTIVE     ? <OptionVideo />     : '' : '' : <ContaLogin /> : '' }
              { isClickedEmail     ? configData.SIGNED ? !configData.INACTIVE ? configData.EMAIL_ACTIVE     ? <OptionEmail />     : '' : '' : <ContaLogin /> : '' }
              { isClickedWhats     ? configData.SIGNED ? !configData.INACTIVE ? configData.WHATS_ACTIVE     ? <OptionWhats />     : '' : '' : <ContaLogin /> : '' }
              { isClickedAppStores ? configData.SIGNED ? !configData.INACTIVE ? configData.APPSTORES_ACTIVE ? <OptionAppStores /> : '' : '' : <ContaLogin /> : '' }
              { isClickedWifi      ? configData.SIGNED ? !configData.INACTIVE ? configData.WIFI_ACTIVE      ? <OptionWifi />      : '' : '' : <ContaLogin /> : '' }
              { isClickedSms       ? configData.SIGNED ? !configData.INACTIVE ? configData.SMS_ACTIVE       ? <OptionSms />       : '' : '' : <ContaLogin /> : '' }
              { isClickedPodCast   ? configData.SIGNED ? !configData.INACTIVE ? configData.PODCAST_ACTIVE   ? <OptionPodcast />   : '' : '' : <ContaLogin /> : '' }
              { isClickedFacebook  ? configData.SIGNED ? !configData.INACTIVE ? configData.FACEBOOK_ACTIVE  ? <OptionFacebook />  : '' : '' : <ContaLogin /> : '' }
              { isClickedInstagram ? configData.SIGNED ? !configData.INACTIVE ? configData.INSTAGRAM_ACTIVE ? <OptionInstagram /> : '' : '' : <ContaLogin /> : '' }
              { isClickedLinkedin  ? configData.SIGNED ? !configData.INACTIVE ? configData.LINKEDIN_ACTIVE  ? <OptionLinkedin />  : '' : '' : <ContaLogin /> : '' }
              { isClickedTwitter   ? configData.SIGNED ? !configData.INACTIVE ? configData.TWITTER_ACTIVE   ? <OptionTwitter />   : '' : '' : <ContaLogin /> : '' }
              { isClickedFile      ? configData.SIGNED ? !configData.INACTIVE ? configData.FILE_ACTIVE      ? <OptionFile />      : '' : '' : <ContaLogin /> : '' }
              { isClickedMp3       ? configData.SIGNED ? !configData.INACTIVE ? configData.MP3_ACTIVE       ? <OptionMp3 />       : '' : '' : <ContaLogin /> : '' }
              { isClickedSmartPack ? configData.SIGNED ? !configData.INACTIVE ? configData.SMARTPACK_ACTIVE ? <OptionSmartPack /> : '' : '' : <ContaLogin /> : '' }
            </div>
            <div style={{ display: 'none' }} className="wrpper-opt-2"> 
              <div className="tab-wrapper" >
                <Tabs defaultActiveKey="3">
                  <TabPane style={{ display: 'none' }} className="tabpane" tab="Molduras" key="1">
                    <div className="frame-container">
                      <div className="frame-opt frame-none">
                        <img src="qrCodeExample.PNG" className="qrcodeImg-tab" alt="" />
                      </div>
                      <div className="frame-opt frame-1">
                        <img src="qrCodeExample.PNG" className="qrcodeImg-tab" alt="" />
                      </div>
                      <div className="frame-opt frame-2">
                        <img src="qrCodeExample.PNG" className="qrcodeImg-tab" alt="" />
                      </div>
                      <div className="frame-opt frame-3">
                        <img src="qrCodeExample.PNG" className="qrcodeImg-tab" alt="" />
                      </div>
                      <div className="frame-opt frame-4">
                        <img src="qrCodeExample.PNG" className="qrcodeImg-tab" alt="" />
                      </div>
                      <div className="frame-opt frame-5">
                        <Button>...</Button>
                      </div>
                    </div>
                  </TabPane>
                  <TabPane style={{ display: 'none' }} className="tabpane" tab="Cor e forma" key="2">
                    <div className="ColorShape-container">
                      <div className="ColorShape-1">
                        <Text className="txt-colorshape"> Change color </Text>
                        <div  className="color-picker">
                          <ColorPicker width={88} height={88} color={color} onChange={setColor} hideRGB hideHSB />
                        </div>
                      </div>
                      <div className="ColorShape-2">
                        <div className="shape-1"></div>
                        <div className="shape-2">
                          <img src="shape2.PNG" className="qrcode-img-tab" alt="" />
                        </div>
                        <div className="shape-3">
                          <img src="shape3.PNG" className="qrcode-img-tab " alt="" />
                        </div>
                      </div>
                    </div>
                  </TabPane>
                  <TabPane className="tabpane" tab="Logotipo" key="3">
                    <div className="logo-container">
                      <div className="logo-1" onClick={handleImgd}>
                        <div className="logo-2">
                          <div className="img-logo">
                            <img src="qrCodeExample.PNG" className="logo-img-tab" alt="" />
                          </div>
                        </div>
                      </div>
                      <div className="logo-3">
                        <ImgCrop grid modalTitle="Recortar Imagem">
                          <Upload className="btn-edit btn-size zero-padding" accept="image/png, image/jpg, image/jpeg"  disabled={!isValidQR} beforeUpload={onPreview}>Upload image</Upload>
                        </ImgCrop>
                        <Text className="finaldesctxt" style={{ padding: 8, fontSize: 12 }}>accept files png / jpg / jpeg</Text>
                      </div>
                    </div>
                  </TabPane>
                </Tabs>
              </div>
            </div>
          </div>
          <div className="result-wrapper">
            <QRComponent />
          </div>
        </div>
          <ShortUrlComponent />
      </QrContext.Provider>
      <Text className="mark">1.2.4 - Powered by Midiacode</Text>
    </>
  );
}

export default App;
