import React, { useState } from 'react';
import configData from './QRcodeAppConfig.json';
import { Input, Button, Typography, Form, message } from 'antd';
import { CopyFilled } from '@ant-design/icons';

const { Title } = Typography;
const { Item } = Form;

const ShortUrlComponent = () => {

  const [value, setValue]     = useState('');
  const [active, setActive]   = useState('sucess');
  const [activeBtn, setActiveBtn]   = useState(false);
  const [validValue, setValidValue]   = useState('');
  const [isCopy, setIsCopy] = useState(false);
  // const [toShort, setToShort] = useState('');

  const handleSubmitShorter = (e) => {
    e.preventDefault();
    fetch(configData.SHORT_FETCH, {
      method: 'post',
      headers: { 
        'Content-Type': 'application/json',
        // 'Accept': 'application/json',
        'Authorization': 'Token dda6a3bbf2fc6772ed1fc568a338f5df85eade20'
      },
      body: JSON.stringify(
        {
          "url_to_short": validValue
        }
      )
    }).then(r =>r.json()).then(r => {
      // console.log(r);
      setValue(r.shorter_url);
      setIsCopy(true);
    });
  }

  const copying = () => {
    navigator.clipboard.writeText(value);
    message.info('URL copied to clipboard');
  }



  const validating = (e) => {
    // eslint-disable-next-line
    let regex = new RegExp(/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/gm);
    let obj = e.target.value;
    if(obj.match(regex)) {
      setActive('sucess');
      setActiveBtn(true);
      setValidValue(e.target.value)
    } else {
      setActive('error');
      setActiveBtn(false);
    }

  };

  return (
    <>
      <div className="shorter-container-total">
        <Title level={1} className="txt-short title-txt">URL Shortner</Title>
        <div className="short-box">
          <div className="short-container">
          <Item
            hasFeedback
            validateStatus={active} help={active === "sucess" ? '' : 'Invalid URL!'}
          >

            <Input onChange={validating} className="inpt-short" placeholder="Enter your URL here..." />
          </Item>
            <Button disabled={activeBtn ? false : true} onClick={handleSubmitShorter} block className="btn-short btn-edit btn-size btns-final">Shorten link</Button>
          </div>
          <div className="result-container">
            <Input readOnly className="inpt-short-read" placeholder="Your short link appears here" value={value} />
            <Button onClick={copying} disabled={isCopy ? false : true} icon={<CopyFilled className="iconizinho" />} block className="btn-copy btn-short btn-edit btn-size ">Copy</Button>
          </div>
        </div>
      </div>
    </>
);

}

export default ShortUrlComponent;
/*
(?:^[h][t][t][p][s]?[:][/][/])?(?:[www]?)?(?:[a-zA-Z0-9]{1,30})(?:[.])(?:[a-zA-Z0-9]{1,30})(?:[.])?(?:[a-zA-Z0-9]{1,30})?
{
	"url_to_short": "https://url.to.reduce.com"
}
*/