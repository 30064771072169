import { Typography, Input, Button, Radio } from 'antd';
import { useContext, useEffect, useState } from 'react';
import QrContext from '../contextQrCode';
import configData from '../QRcodeAppConfig.json';

const { Text } = Typography;

const OptionWifi = (isEnter) => {

  const {setWifiQR, setIsValidQR} = useContext(QrContext);


  const [value, setValue] = useState(1);
  const onChange = e => setValue(e.target.value);
  
  const [nameID, setNameID] = useState('');
  const [pass, setPass] = useState('');
  const [sec, setSec] = useState('');

  useEffect(() => {
    if(value === 1) { setSec('WPA') } else
    if(value === 2) { setSec('WEP') } else
    if(value === 3) { setSec('') };
    setWifiQR(`WIFI:S:${nameID};T:${sec};P:${pass}`);
  }, [sec, value, nameID, pass, setWifiQR]);


  // WIFI:S:teste;T:nopassP:teste;;
  // WIFI:T:WPA;S:<SSID>;P:<PASSWORD>;;
  return (
  <>
    <Typography className={`typography-container ${isEnter ? 'opt-enter' : 'opt-out'}`}>
      <Text className="txt-desc">Enter your Wifi data.</Text>
      <Input className="margins" placeholder="Network name (SSID)" onChange={
        (e) => {
          setIsValidQR(true);
          setNameID(e.target.value);
        }
      } />
      <Input className="margins" placeholder="password"  onChange={
        (e) => {
          setPass(e.target.value);
        }
      } />
      
      <div className="btns-container-opts">
      <Text className="colorft">Security:</Text>
      <Radio.Group onChange={onChange} value={value}>
        <Radio className="colorft" value={1}>WPA/2</Radio>
        <Radio className="colorft" value={2}>WEP</Radio>
        <Radio className="colorft" value={3}>None</Radio>
      </Radio.Group>
      </div>

      <div className="btns-container-opts">
        <Button href={configData.DEFAULT_LINK_3} target="_blank" type="primary" className="margins" shape="round"> 
        {configData.BTN_AZUL}
        </Button>
      </div>
    </Typography>
  </>
);

}

export default OptionWifi;