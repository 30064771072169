import { Typography, Input, Button, Form } from 'antd';
import { useContext, useEffect, useState } from 'react';
import QrContext from '../contextQrCode';
import configData from '../QRcodeAppConfig.json';
const { Text } = Typography;
const { TextArea } = Input;
const { Item } = Form;

const OptionWhats = (isEnter) => {

  const {setWhatsQR, isValidQR, setIsValidQR} = useContext(QrContext);

  const [whatsNumber, setWhatsNumber] = useState('');
  const [whatsMsg, setWhatsMsg] = useState('');

  const [valStatus, setValStatus] = useState('sucess');

  
  const validator = (objUrl = '') => {
    let regex = new RegExp(/(?:[^\n](?:(\d{2,3})?(\+)?(\d{2,3})?(\s)?(\()?)(?:\d{2,3})(?:(\s)?(\))?(\s)?(-)?)(?:\d{4,5})(?:(-?)(\d{4,5})?(\d*)?)(?:(\s)?(-)?(\d{2,3})(\s)(\d{2,3})?)?)/g);
    let result = '';
    if(objUrl.match(regex)) {
      let teste = regex.exec(objUrl);
      result = teste[0];
      console.log( result);
  
      setValStatus('sucess');
      setWhatsNumber(result);
      setIsValidQR(true);
    } else {
      setIsValidQR(false);
      setValStatus('error');
      // setWhatsNumber('invalid');
    }
  };

  useEffect(() => {
      setWhatsQR(`https://api.whatsapp.com/send?phone=${whatsNumber}&text=${whatsMsg}`);
  }, [whatsNumber, whatsMsg, setWhatsQR]);

  // (?:[^\n](?:(\d{2,3})?(\+)?(\d{2,3})?(\s)?(\()?)(?:\d{2,3})(?:(\s)?(\))?(\s)?(\-)?)(?:\d{4,5})(?:(\-?)(\d{4,5})?(\d*)?)(?:(\s)?(\-)?(\d{2,3})(\s)(\d{2,3})?)?)
  // https://api.whatsapp.com/send?phone=550&text=TESTE
  return (
  <>
    <Typography className={`typography-container ${isEnter ? 'opt-enter' : 'opt-out'}`}>
      <Text className="txt-desc">Put your whatsapp number + DDD and the message you want to send</Text>
      
      <Item
        hasFeedback
        validateStatus={valStatus} help={isValidQR ? '' : 'invalid number'}
      >
        <Input className="margins" placeholder="Whatsapp number" onChange={
          (e) => {
            e.preventDefault();
            validator(e.target.value);
          }
        } />
      </Item>
      <TextArea rows={6} className="txtAreaOpt" placeholder="Message" onChange={
        (e) => {
          e.preventDefault();
          setWhatsMsg(e.target.value);
        }
      } />
      <div className="btns-container-opts">
        <Button href={configData.DEFAULT_LINK_3} target="_blank" type="primary" className="margins" shape="round"> 
        {configData.BTN_AZUL}
        </Button>
      </div>
    </Typography>
  </>
);

}

export default OptionWhats;