import { Typography, Button } from 'antd';
import configData from '../QRcodeAppConfig.json';

const { Text } = Typography;
const { Title } = Typography;

const OptionPdf = () => {
  

  return (
  <>
    <Typography className="typography-container">
      <Title level={5} className="txt-desc">{configData.PDF_TITLE}</Title>
      <Text className="txt-desc"> 
        {configData.PDF_TEXT}
      </Text>
      <div className="btns-container-opts">
        <Button href={configData.DEFAULT_LINK_1} target="_blank" className="margins" shape="round" type="default"> Login </Button>
        <Button href={configData.DEFAULT_LINK_2} target="_blank" className="margins" shape="round" type="primary" style={{color: "white"}}> Create a free account! </Button>
      </div>
    </Typography>
  </>
);

}

export default OptionPdf;