/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState, useRef} from 'react';
import { Button, message, Typography } from 'antd';
import configData from '../QRcodeAppConfig.json';
import QrContext from '../contextQrCode';
import QRCodeStyling from 'qr-code-styling';

const { Text } = Typography;

const QRComponent = () => {

  const [value, setValue] = useState('');
  const refQr = useRef(null);

  const { 
    urlQR, isClickedUrl,
    textQR, isClickedText,
    emailQR, isClickedEmail,
    youtubeQR, isClickedYoutube,
    videoQR, isClickedVideo,
    whatsQR, isClickedWhats,
    podcastQR, isClickedPodCast,
    facebookQR, isClickedFacebook,
    instagramQR, isClickedInstagram,
    linkedinQR, isClickedLinkedin,
    twitterQR, isClickedTwitter,
    fileQR, isClickedFile,
    mp3QR, isClickedMp3,
    wifiQR, isClickedWifi,
    smsQR, isClickedSms,
    isValidQR, setIsValidQR, imgd
  } = useContext(QrContext);

  
  const qrCode = new QRCodeStyling({
    width: 200,
    height: 200, 
    type: 'canvas',
    margin: 4,
    dotsOptions: { color: '#000000' },
    backgroundOptions: { color: '#ffffff' }
  });

  useEffect(() => {
  const generateQRbyComponent = () => {

    if (value !== 'invalid') {
      if(isClickedUrl) {
        setValue(''); 
        setValue(urlQR);
      }
      if(isClickedText) {
        setValue('');
        setValue(textQR);
      }
      if(isClickedEmail) {
        setValue('');
        setValue(emailQR);
      }
      if(isClickedYoutube) {
        setValue('');
        setValue(youtubeQR);
      }
      if(isClickedVideo) {
        setValue('');
        setValue(videoQR);
      }
      if(isClickedWhats) {
        setValue('');
        setValue(whatsQR);
      }
      if(isClickedPodCast) {
        setValue('');
        setValue(podcastQR);
      }
      if(isClickedFacebook) {
        setValue('');
        setValue(facebookQR);
      }
      if(isClickedInstagram) {
        setValue('');
        setValue(instagramQR);
      }
      if(isClickedLinkedin) {
        setValue('');
        setValue(linkedinQR);
      }
      if(isClickedTwitter) {
        setValue('');
        setValue(twitterQR);
      }
      if(isClickedFile) {
        setValue('');
        setValue(fileQR);
      }
      if(isClickedMp3) {
        setValue('');
        setValue(mp3QR);
      }
      if(isClickedWifi) {
        setValue('');
        setValue(wifiQR);
      }
      if(isClickedSms) {
        setValue('');
        setValue(smsQR);
      }
    }
  };
    generateQRbyComponent();
    qrCode.append(refQr.current);
    qrCode.update({ data: value, image: imgd, imageOptions: { crossOrigin: "anonymous", margin: 4 } });
  }, [
      urlQR, isClickedUrl,
      textQR, isClickedText,
      emailQR, isClickedEmail,
      youtubeQR, isClickedYoutube,
      videoQR, isClickedVideo,
      whatsQR, isClickedWhats,
      podcastQR, isClickedPodCast,
      facebookQR, isClickedFacebook,
      instagramQR, isClickedInstagram,
      linkedinQR, isClickedLinkedin,
      twitterQR, isClickedTwitter,
      fileQR, isClickedFile,
      mp3QR, isClickedMp3,
      wifiQR, isClickedWifi,
      smsQR, isClickedSms,
      value, setValue,
      setIsValidQR, qrCode
    ]
  );


  const downloadQRCodeasSVG = async () => {
    qrCode.update({ type: 'svg' });
    qrCode.download({ name: 'QR Code Powered by Midiacode', extension: 'svg' });
  };

  const downloadQRCodeasPNG = () => {
    qrCode.update({ type: 'png' });
    qrCode.download({ name: 'QR Code Powered by Midiacode', extension: 'png' });
  };

  const clipboardSVG = () => {
    qrCode.update({ type: 'svg' });
    let qr = document.getElementById('qrcode-container3');
    let svgString = new XMLSerializer().serializeToString(qr.firstChild);
    console.log(svgString);
    navigator.clipboard.writeText(svgString);
    message.info('Copiado em svg!');
    qrCode.update({ type: 'canvas' });
  }

  return (
  <>
  <div className="qrcode-container">
    <div className="qrcodegen-container" >
      <div id="qrcode-container3" style={{display: isValidQR ? '' : 'none'}} ref={refQr}></div>
    </div>
    <div className="txt-desc">
    <Text className="finaldesctxt">
    Right-click on the qr code to copy it into PNG. Click
      {/* eslint-disable-next-line  */  }
      <a className="link-copy"  disabled={configData.INACTIVE || !isValidQR} onClick={clipboardSVG} > here</a> to copy the qr code into SVG.
    </Text>
    </div>
    <div className="btns-down-container">
      <div className="btns-down">
        <Button onClick={downloadQRCodeasPNG} disabled={configData.INACTIVE || !isValidQR} block className="btn-edit btn-size btns-final">Download QR code - PNG</Button>
        
      </div>
      <div className="btns-down">
        <Button onClick={downloadQRCodeasSVG} disabled={configData.INACTIVE || !isValidQR} block className="btn-edit btn-size btns-final">Download QR code - SVG</Button>
      </div>
    </div>
  </div>
  </>
);

}

export default QRComponent;