import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

// Configurando o ant design e sua internacionalização em pt br ___
import { ConfigProvider } from 'antd';
import ptBR from 'antd/lib/locale/pt_BR';
import 'antd/dist/antd.css';
// ‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾

ReactDOM.render(
    <ConfigProvider locale={ptBR}>
      <App />
    </ConfigProvider>,
  document.getElementById('root')
);
