import { Typography, Button } from 'antd';
import configData from '../QRcodeAppConfig.json';

const { Text } = Typography;
const { Title } = Typography;

const OptionSmartPack = () => {
  

  return (
  <>
    <Typography className="typography-container">
      <Text className="txt-desc">Faça login e crie sua Embalagem Inteligente com QR code</Text>
      <Title className="txt-desc" level={5}> 
        Para criar seu QR Code de Embalagem Inteligente, faça login e crie um NOVO CONTEÚDO. <br /> 
        Siga os 3 passos para criar seu QR Code. <br />
        Se você ainda não tem uma conta, crie uma grátis...
      </Title>
      <div className="btns-container-opts">
        <Button href={configData.DEFAULT_LINK_1} target="_blank" className="margins" shape="round" type="primary"> fazer login </Button>
        <Button href={configData.DEFAULT_LINK_2} target="_blank" className="margins" shape="round" type="primary"> crie uma conta grátis! </Button>
      </div>
    </Typography>
  </>
);

}

export default OptionSmartPack;