import { Typography, Button } from 'antd';
import configData from '../QRcodeAppConfig.json';

const { Text } = Typography;
const { Title } = Typography;

const ContaLogin = () => {
  

  return (
  <>
    <Typography className="typography-container">
      <Text className="txt-desc">{configData.DEFAULT_TITLE}</Text>
      <Title className="txt-desc" level={5}> 
        { configData.DEFAULT_TEXT }
      </Title>
      <div className="btns-container-opts">
        <Button href={configData.DEFAULT_LINK_1} target="_blank" className="margins" shape="round" type="default"> fazer login </Button>
        <Button href={configData.DEFAULT_LINK_2} target="_blank" className="margins" shape="round" type="primary" style={{color: "white"}}> crie uma conta grátis! </Button>
      </div>
    </Typography>
  </>
);

}

export default ContaLogin;